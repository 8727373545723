import facebookLogo from "../../assets/facebook.png";
import instaLogo from "../../assets/insta.png";
import untappdLogo from "../../assets/untappd.png";

import classes from "./Footer.module.css";

const Footer = () => {
  const facebookUrl = "https://www.facebook.com/coteabeausoleil/";
  const instaUrl = "https://www.instagram.com/brasseriecoteabeausoleil/";
  const untappdUrl = "https://untappd.com/CoteBeausoleil";

  return (
    <footer className={classes.footer}>
      <div className={classes.socials}>
        <a href={facebookUrl}>
          <img src={facebookLogo} alt="facebook" />
        </a>
        <a href={untappdUrl}>
          <img src={untappdLogo} alt="untappd" />
        </a>
        <a href={instaUrl}>
          <img src={instaLogo} alt="instagram" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
