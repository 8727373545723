import classes from "./Header.module.css";
import coteLogoSrc from "../../assets/logo.svg";

const Header = () => {
  const coteLogo = (
    <img
      className={classes["main-logo"]}
      src={coteLogoSrc}
      alt="Cote A Beausoleil Logo"
    />
  );

  return (
    <header className={classes.header}>
      {coteLogo}
    </header>
  );
};

export default Header;
