import { useEffect, useState } from "react";

import Card from "../UI/Card";
import LoadingSpinner from "../UI/LoadingSpinner";

import coteLogoSrc from "../../assets/logo.svg";
import classes from "./Item.module.css";

const cansUrl = '/api/products?type=cans'

const Cans = () => {
  const [cans, setCans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(cansUrl);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const data = await response.json();
      setCans(data);

      setIsLoading(false);
    };

    fetchData().catch((error) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, []);

  if (isLoading) {
    return (
      <main className={classes.main}>
        <LoadingSpinner />
      </main>
    );
  }

  if (httpError) {
    return <p className={classes.error}>{httpError}</p>;
  }

  return (
    <main className={classes.main}>
      <ul>
        {cans.map(
          (item, i) =>
            item.available && (
              <li key={i}>
                <Card>
                  <div className={classes.image}>
                    <img
                      src={
                        item.largeImageUrl
                        ?? coteLogoSrc
                      }
                      alt="thumbnail"
                    />
                  </div>
                  <div className={classes.content}>
                    <h1>{item.name}</h1>
                    <p>{item.description}</p>
                    <h3>IBU: {item.ibu}</h3>
                    <h3>ABV: {item.abv * 100}%</h3>
                  </div>
                </Card>
              </li>
            )
        )}
      </ul>
    </main>
  );
};

export default Cans;
