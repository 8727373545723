import { NavLink } from "react-router-dom";

import classes from "./Menu.module.css";

const Menu = () => {
  return (
    <section className={classes.section}>
      <nav>
        <ul>
          <li>
            <NavLink activeClassName={classes.active} to="/tap">
              En Fût
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={classes.active} to="/cans">
              Canettes
            </NavLink>
          </li>
        </ul>
      </nav>
    </section>
  );
};

export default Menu;
