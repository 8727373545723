import { Route, Redirect } from "react-router";

import Menu from './components/Layout/Menu';
import Tap from "./components/Drinks/Tap";
import Cans from "./components/Drinks/Cans";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";

function App() {
  return (
    <>
      <Header />
      <Menu />
      <Route path="/" exact>
        <Redirect to="/tap" />
      </Route>
      <Route path="/tap">
        <Tap />
      </Route>
      <Route path="/cans">
        <Cans />
      </Route>
      <Footer />
    </>
  );
}

export default App;
