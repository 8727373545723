import classes from "./Card.module.css";

const Card = (props) => {
  return (
    <section className={classes.cards}>
      <article className={classes.card}>{props.children}</article>
    </section>
  );
};

export default Card;
